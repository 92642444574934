var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('VoucherAddEdit',{attrs:{"es-id":_vm.ae.esId,"primary-key":_vm.ae.primaryKey,"form-data":_vm.ae.formData,"is-editing":_vm.ae.isEditing,"fleet-currency":_vm.ae.fleetCurrency,"currency-symbol":_vm.ae.currencySymbol,"fleetList":_vm.fleetList,"currencies":_vm.currencies},on:{"save":function () {
        _vm.$edgeStack.shouldNotConfirm(_vm.ae.esId)
        _vm.$store.dispatch('fsTable/fetchData')
      },"close":function($event){return _vm.$edgeStack.shouldNotConfirm(_vm.ae.esId)}}}),_c('NotifyRider',{attrs:{"esId":_vm.details.esId,"busy":_vm.details.busy,"riderData":_vm.rider,"rawData":_vm.details.rawData,"fleets":_vm.fleets,"primaryKey":_vm.details.primaryKey,"fleetList":_vm.fleetList,"currencies":_vm.currencies},on:{"edgestack":function($event){return _vm.onEdgeStack({ esId: _vm.addEdit.esId, primaryKey: $event.taskId })}}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('TitlePlus',{attrs:{"title":_vm.$t('components.offers.marketplace.headline')},on:{"plus":_vm.onOpenES}}),_c('div',{staticClass:"flex md:justify-end"},[_c('date-range-picker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)],1),_c('div',{staticClass:"summary-card-container"},_vm._l((_vm.getSummaries),function(summary){return _c('SummaryCard',{key:"SummaryCard-" + summary.title,attrs:{"title":summary.title,"value":summary.value,"variant":summary.variant,"icon":summary.variant}})}),1)]),_c('FSTable',{attrs:{"qso":_vm.qso,"fst-id":_vm.fstId,"headers":_vm.tableHeaders,"endpoint":_vm.indexDataEndpoint,"text-fallback-always":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
      var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":_vm.fstId,"slot-width":slotWidth,"options":_vm.filterOptions}})],1)]}},{key:"default",fn:function(ref){
      var data = ref.data;
return _vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"to":{
              name: 'MarketingCampaignDetailsIndex',
              params: { id: item.id },
            }}}),_c('FSTableRowItem',{attrs:{"text":item.title}}),_c('FSTableRowItem',{attrs:{"text":item.description}}),_c('FSTableRowItem',[(item.assigned_fleets && item.assigned_fleets.length > 0)?_c('div',{staticClass:"flex items-center gap-2 flex-wrap"},_vm._l((item.assigned_fleets),function(fleet,key){return _c('RouterLink',{key:key,staticClass:"text-blue-600",attrs:{"to":{ name: 'ViewFleetProfile', params: { id: fleet.id } },"target":"_blank"}},[_vm._v(_vm._s(fleet.name))])}),1):_vm._e()]),_c('FSTableRowItem',[_vm._v(" "+_vm._s(_vm._f("friendlyDateTime")(item.expires_at))+" "),_c('p',[_vm._v(" ("),_c('TimeAgo',{attrs:{"title":item.expires_at,"datetime":item.expires_at,"auto-update":60}}),_vm._v(") ")],1)]),_c('FSTableRowItem',[_vm._v(" "+_vm._s(_vm._f("friendlyDateTime")(item.updated_at))+" "),_c('p',[_vm._v(" ("),_c('TimeAgo',{attrs:{"title":item.updated_at,"datetime":item.updated_at,"auto-update":60}}),_vm._v(") ")],1)]),_c('FSTableRowItem',[_c('x-status',{attrs:{"text":item.status,"variant":_vm.getItemVariant(item.status)}})],1),_c('FSTableRowItem',[(item.status !== 'Expired')?_c('div',{staticClass:"flex items-center gap-1"},[_c('OtoEditIcon',{on:{"click":function($event){return _vm.onOpenES(item.id)}}}),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Notify Riders'),expression:"'Notify Riders'",modifiers:{"top":true}}],staticClass:"\n              flex\n              items-center\n              justify-center\n              px-2\n              py-0\n              font-medium\n              border\n              rounded-full\n              shadow-sm                \n              h-35px\n              text-14px text-oWhite\n              focus:border-border-oWhite                \n              focus:outline-none\n              \n            ",class:[
                  item.status === 'Active'
                    ? 'bg-blue-600 hover:bg-blue-700'
                    : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed' ],on:{"click":function($event){return _vm.onEdgeStack({
                    esId: _vm.details.esId,
                    primaryKey: item.id,
                    rawData: item,
                  })}}},[_c('div',{staticClass:"flex items-center "},[_c('i',{staticClass:"mr-2 fas fa-paper-plane"})])])],1):_vm._e()])],1)})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }